import React, { createContext, useReducer, useEffect } from 'react'
import { node } from 'prop-types'

import { authReducer, defaultForm } from '../../../reducer/auth-reducer'
import LoadingOverlay from '@/component/Primitive/LoadingOverlay'
import Router, { useRouter } from 'next/router'
import { getUser } from 'src/lib/auth-api'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const router = useRouter()
  const localState = process.browser
    ? JSON.parse(sessionStorage.getItem('auth'))
    : undefined

  const initialState = {
    loginForms: defaultForm(),
    user: localState || null
  }
  const [auth, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    setLoading(true)
    setHasMounted(true)

    getUser()
      .then((user) => {
        dispatch({ type: 'SET_USER', user })
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        dispatch({ type: 'LOGOUT' })
        setLoading(false)
      })
  }, [hasMounted])

  useEffect(() => {
    sessionStorage.setItem('auth', JSON.stringify(auth.user))
  }, [auth])

  const loadingOrServer = !hasMounted || loading
  const innerContext = loadingOrServer ? (
    <div style={{ height: '100vh', width: '100vw' }} />
  ) : (
    children
  )

  if (
    hasMounted &&
    !auth.user &&
    !(
      router.pathname.includes('auth') ||
      router.pathname.includes('/favourites') ||
      router.pathname.includes('/valuation/view')
    )
  ) {
    Router.replace('/auth/login')
  }

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      <LoadingOverlay loading={loadingOrServer}>{innerContext}</LoadingOverlay>
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: node.isRequired
}

export { AuthContext, AuthProvider }
