import React from 'react'
import { func, object } from 'prop-types'
import Head from 'next/head'

import '../CitygateDesignSystem/src/asset/scss/base.scss'

import * as Sentry from '@sentry/react'

import { ValuationDataProvider } from 'CGV/component/Context/DataContext'
import { ValuationFormProvider } from 'CGV/component/Context/FormContext'
import { AuthProvider } from 'src/component/Context/AuthContext'

Sentry.init({
  dsn: 'https://d99a45ae14434ae59a49a59bc0b571c8@o36550.ingest.sentry.io/5305680'
})

const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      <Head />
      <AuthProvider>
        <ValuationDataProvider>
          <ValuationFormProvider>
            <Component {...pageProps} />
          </ValuationFormProvider>
        </ValuationDataProvider>
      </AuthProvider>
    </>
  )
}

MyApp.propTypes = {
  Component: func,
  pageProps: object
}

export default MyApp
